<template>
  <div id="app" class="bg-primary-default">
    <div class="mb-5 flex justify-end gap-x-2">
      <router-link to="/">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-8 w-8"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
          />
        </svg>
      </router-link>
      <div @click="menuToggle()">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-8 w-8"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M4 6h16M4 12h16m-7 6h7"
          />
        </svg>
      </div>
    </div>
    <div
      v-if="menu"
      class="
        h-screen
        w-screen
        bg-primary-default bg-opacity-70
        right-0
        top-0
        fixed
        z-50
        flex
        justify-end
      "
    >
      <div class="w-2/3 bg-primary-full py-20 px-5">
        <div class="absolute right-5 top-4" @click="menuToggle()">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </div>
        <ul class="text-xl text-right">
          <!-- <li class="mb-4">
            <router-link to="/" class="text-white font-bold"
              >Dashboard</router-link
            >
          </li> -->
          <li class="my-4">
            <router-link to="/fencers" class="text-white font-bold"
              >Mes tireurs</router-link
            >
          </li>
          <li class="my-4">
            <router-link to="/competitions" class="text-white font-bold"
              >Mes compétitions</router-link
            >
          </li>
          <!-- <li class="my-4">
            <router-link to="/" class="text-white font-bold"
              >Mon profil</router-link
            >
          </li> -->
        </ul>
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      menu: false,
    };
  },
  watch: {
    $route() {
      this.menu = false;
    },
  },
  methods: {
    menuToggle() {
      this.menu = !this.menu;
    },
  },
};
</script>

