import axios from 'axios'
class Api {

    constructor(url) {
        this.axios = axios.create({
          baseURL: url,
        })
    }    

    sendEmail(form) {
        return this.axios.post('email', {params: form})
    }

    getFencers() {
        return this.axios.get('fencers')
    }
    getFencer(fencerId) {
        return this.axios.get('fencers/single/'+fencerId)
    }
    createFencer(fencer) {
        return this.axios.post(`fencers/insert`, {params: fencer})
    }
    deleteFencer(fencerId) {
        return this.axios.post('fencers/'+fencerId+'/delete')
    }

    getCompetitions(state) {
        return this.axios.post('competitions', {params: {state: state}})
    }
    createCompetition(competition) {
        return this.axios.post('competitions/insert', {params: competition})
    }
    getCompetition(competitionId) {
        return this.axios.get('competitions/single/'+competitionId)
    }
    deleteCompetition(competitionId) {
        return this.axios.post('competitions/'+competitionId+'/delete'
        )
    }
    createTeam(team, competitionId) {
        return this.axios.post('competitions/'+competitionId+'/update', 
            { params: {
                'team': team,
            } }
        )
    }
    createMatch(match, competitionId) {
        return this.axios.post('competitions/'+competitionId+'/match/insert', 
            { params: match }
        )
    }
    updateMatch(club1, club2, matchId, competitionId) {
        return this.axios.post('competitions/'+competitionId+'/matches/'+matchId+'/update', 
            { params: {'club1':club1, 'club2':club2} }
        )
    }
    getMatch(matchId, competitionId) {
        return this.axios.get('competitions/'+competitionId+'/matches/'+matchId)
    }
    saveMatch(competitionId, matchId, club1, club2, winner) {
        return this.axios.post('competitions/'+competitionId+'/matches/'+matchId+'/save', 
            { params: {'club1':club1, 'club2':club2, 'result':winner} }
        )
    }
    deleteMatch(competitionId, matchId) {
        return this.axios.post('competitions/'+competitionId+'/matches/'+matchId+'/delete'
        )
    }
    
}

export default new Api(process.env.VUE_APP_API_URL)
