import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import "tailwindcss/tailwind.css"
import './assets/styles/main.css';
import './helpers/filters'
import router from './router'
import '@/api';

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')

router.beforeEach((to, _from, next) => {
  if(to.name != 'single-match'){
    localStorage.removeItem('club1');
    localStorage.removeItem('club2');
    localStorage.removeItem('relay');
  }
  next()
})