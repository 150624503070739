import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/Home.vue'),
    name: 'home'
  },
  {
    path: '/competitions',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/Competitions.vue'),
    name: 'list-competitions'
  },
  {
    path: '/competitions/:competitionsId',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/id_Competition.vue'),
    name: 'list-match'
  },
  {
    path: '/competitions/:competitionsId/matches/:matchId',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/id_Match.vue'),
    name: 'single-match'
  },
  {
    path: '/fencers',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/Fencers.vue'),
    name: 'list-fencers'
  },
  {
    path: '/fencers/:fencerId',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/id_Fencer.vue'),
    name: 'single-player'
  },
  {
    path: '/account',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/Account.vue'),
    name: 'account'
  },
]

const router = new VueRouter({
  mode: 'history',
  // base: process.env.BASE_URL,
  routes
})

export default router
