import Vue from "vue"
import dayjs from 'dayjs'


Vue.filter('state', function (value) {
    if (!value) return ''
    if(value == 'created'){
      return 'Créée'
    }
    if(value == 'pending'){
      return 'En cours'
    }
    if(value == 'over'){
      return 'Terminé'
    }
})

Vue.filter('formatDate', function (date) {
  if (date == null) return '';
  const d = dayjs(date);
  return d.format('DD-MM-YYYY');
})

Vue.filter('category', function (year) {
  if (!year) return ''
  var now = new Date().getFullYear();
  if(year <= now && year >= now - 14){
    return 'M14'
  }
  if(year < now - 14 && year >= now - 17){
    return 'M17'
  }
  if(year < now - 17 && year >= now - 20){
    return 'M20'
  }
  if(year < now - 20 && year >= now - 23){
    return 'M23'
  }
  if(year < now - 23 && year > now - 40){
    return 'Senior'
  }
  if(year >= now-40){
    return 'Vétéran'
  }
})